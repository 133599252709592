import {
    Box,
    Avatar,
    Heading,
    Stack,
    Text,
    Center,
    useColorModeValue,
} from '@chakra-ui/react';
import AndrewPic from "../images/55984FFB-95DF-4CF1-B4AB-3ECC4B029C0B.jpeg";
export { Head } from "../seo";


const aboutAndrew = `
I've spent over a decade as a software engineer, building B2B software for email marketing, 
terabyte scale ML, urban mobility, and most recently parking. In my free time I enjoy 
working on cars, playing pickleball, and lounging with my two dogs.
`

const parkifyMission = `
Frustration free parking for every driver. The parking experience starts 
before you get into the car and doesn't end when you leave the lot—the complete lifecycle 
of parking should be integrated into our phones, cars, and other devices. Parkify makes 
it easy for parking operators to bring their buisiness into the world of connected mobility. 
`

const AboutPage = () => {
    return (
        <Box py={12} maxW={'5xl'} mx="auto">
            <Stack>
                <Heading id="the-mission" py={12} px={4}>The Mission</Heading>
                <Text maxW={{ sm: "100%", md: "80%" }} px={4}>{parkifyMission}</Text>
                <Heading id="the-team" py={12} px={4}>The Team</Heading>
                <Stack
                    w="100%"
                    py={12}
                    height={{ sm: '476px', md: '20rem' }}
                    direction={{ base: 'column', md: 'row' }}
                    bg={useColorModeValue('white', 'gray.900')}
                    padding={4}>

                    <Center flex={1} justifyContent={'center'}>
                        <Avatar
                            size={'2xl'}
                            src={AndrewPic}
                            alt={'Picture of Andrew'}
                        />
                    </Center>
                    <Stack
                        flex={2}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="left"
                        px={4}
                        pt={2}>
                        <Heading fontSize={'2xl'} fontFamily={'body'}>
                            Andrew Tamura
                        </Heading>
                        <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>
                            CEO
                        </Text>
                        <Text color={useColorModeValue('gray.700', 'gray.400')}>
                            {aboutAndrew}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}


export default AboutPage
